@mixin spa-card() {
  background-color: $color-brand-tertiary;
  border-radius: 12px;
  position: relative;
  padding: $spacing-16;
}

@mixin small() {
  padding: $spacing-16;
  .spa-card__foot {
    margin-top: 0;
    margin-left: -$spacing-16;
    margin-right: -$spacing-16;
    margin-bottom: -$spacing-16;
  }

  &.spa-card {
    .spa-card__foot {
      padding-top: $spacing-16;
      &--sticky {
        padding: $spacing-16;
      }
    }

    .spa-btn {
      &:not(.spa-btn--link) {
        font-size: 14px;
        padding: $spacing-8 $spacing-16;
        height: $spacing-32;
        border-radius: $spacing-16;
      }
    }
  }
  .spa-card__head {
    margin: -$spacing-16 (-$spacing-16) $spacing-12 (-$spacing-16);
    .spa-card__dismissible {
      margin: 6px 6px 6px auto;
    }
  }
  .spa-card__title {
    padding: $spacing-16;
    &,
    & h1,
    & h2,
    & h3,
    & h4,
    & h5,
    & h6 {
      font-size: $spacing-16;
    }
  }
}

@mixin medium() {
  padding: $spacing-16;
  .spa-card__foot {
    margin-top: $spacing-16;
    margin-left: -$spacing-16;
    margin-right: -$spacing-16;
    margin-bottom: -$spacing-16;
  }
  .spa-card__head {
    margin: -$spacing-16 (-$spacing-16) $spacing-12 (-$spacing-16);
    .spa-card__dismissible {
      margin: $spacing-12 6px $spacing-12 auto;
    }
  }
  .spa-card__title {
    padding: $spacing-20 $spacing-16;
    &,
    & h1,
    & h2,
    & h3,
    & h4,
    & h5,
    & h6 {
      font-size: $spacing-20;
    }
  }
}

@mixin large() {
  padding: $spacing-24;
  .spa-card__foot {
    margin-top: 0;
    margin-left: -$spacing-24;
    margin-right: -$spacing-24;
    margin-bottom: -$spacing-24;
    padding-top: $spacing-24;

    &-link {
      padding: $spacing-24 0;
    }
  }

    &.spa-card {
    .spa-card__foot {
      &--sticky {
        padding: $spacing-24;
      }
    }
  }
  .spa-card__head {
    margin: -$spacing-24 (-$spacing-24) $spacing-12 (-$spacing-24);
    .spa-card__dismissible {
      margin: $spacing-16 14px $spacing-16 auto;
    }
  }
  .spa-card__title {
    padding: $spacing-24;
    &,
    & h1,
    & h2,
    & h3,
    & h4,
    & h5,
    & h6 {
      font-size: $spacing-20;
    }
  }
}

@mixin card-sizing($reduceFontSize: 0px, $addImportant: null, $includeMediaQueries: true) {



  &--xsmall,
  &--small {
    @include small;
  }

  &--medium {
    padding: $spacing-16 $addImportant;
    .spa-card__foot {
      margin-top: $spacing-16 $addImportant;
      margin-left: -$spacing-16 $addImportant;
      margin-right: -$spacing-16 $addImportant;
      margin-bottom: -$spacing-16 $addImportant;
    }
    .spa-card__head {
      margin: -$spacing-16 (-$spacing-16) $spacing-12 (-$spacing-16) $addImportant;
      .spa-card__dismissible {
        margin: $spacing-12 6px $spacing-12 auto $addImportant;
      }
    }
    .spa-card__title {
      padding: $spacing-20 $spacing-16 $addImportant;
      &,
      & h1,
      & h2,
      & h3,
      & h4,
      & h5,
      & h6 {
        font-size: (20px - $reduceFontSize) $addImportant;
      }
    }

    @if $includeMediaQueries {
      @media #{$small-only} {
        @include small;
      } 
    }
  }

  &--large {
    @include large;

    @if $includeMediaQueries {
      @media #{$small-only} {
        @include small;
      } 
    }
  }
}